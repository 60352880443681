import React from "react";

import async from "../components/Async";
import i18n from "../i18nextConf";

import {
  // BookOpen,
  // Briefcase,
  // Calendar as CalendarIcon,
  // CheckSquare,
  // CreditCard,
  // Grid,
  // Heart,
  // Layout,
  List,
  // Map,
  // Monitor,
  ShoppingCart,
  PieChart,
  Sliders,
  Tool,
  Terminal,
  Settings,
} from "react-feather";

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));
const AuthGuardAdmin = async(() => import("../components/AuthGuardAdmin"));
const AuthGuardAdminMM = async(() => import("../components/AuthGuardMM"));

//Blank
const Blank = async(() => import("../pages/pages/Blank.js"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignInI"));
const SignInB = async(() => import("../pages/auth/SignInB"));
// const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const ResetPasswordBusiness = async(() =>
  import("../pages/auth/ResetPasswordBusiness")
);
const ChangePassword = async(() => import("../pages/auth/ChangePassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const Activate = async(() => import("../pages/auth/Activate"));
const QrCode = async(() => import("../QR_code/QrCode"));

//Devices && Locker components
const DataGridDevices = async(() => import("../devices/DataGridDevices"));
const ShowDevice = async(() => import("../devices/ShowDevice"));

//Shops
const DataGridCompany = async(() => import("../company/DataGridCompany"));
const ShowCompany = async(() => import("../company/ShowCompany"));

//Users
const DataGridUsers = async(() => import("../users/DataGridUsers"));
const EditUser = async(() => import("../users/EditUser"));

//ShopUnit
const DataGridShopUnit = async(() => import("../shopUnit/DataGridShopUnit"));
const EditShopUnit = async(() => import("../shopUnit/EditShopUnit"));
const ShowShopUnit = async(() => import("../shopUnit/ShowShopUnit"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));

const Profile = async(() => import("../pages/pages/Profile"));

// Landing
const Landing = async(() => import("../pages/presentation/Landing"));

//Terminals components
const DataGridTerminals = async(() => import("../terminals/DataGridTerminals"));
const ShowLockerTerminals = async(() => import("../terminals/ShowLocker"));

//Orders compnents
const DataGridOrders = async(() => import("../orders/DataGridOrders"));
const ShowOrder = async(() => import("../orders/ShowOrder"));

//Reports
const ShowBiling = async(() => import("../reports/Biling"));
const ShowActivity = async(() => import("../reports/Activity"));
const ShowErrors = async(() => import("../reports/Errors"));
const ShowFullLog = async(() => import("../reports/FullLog"));

//Languages
const DataGridLang = async(() => import("../languages/DataGridLangs"));
const EditLang = async(() => import("../languages/EditLang"));

//Managment
const EmailTemplatesGrid = async(() =>
  import("../management/emailTemplates/EmailTemplatesGrid")
);

//const { t } = useTranslation();

const nameDashboard = () => {
  return i18n.t("dashboard_helmet");
};

const nameOrders = () => {
  return i18n.t("orders_helmet");
};

const nameTerminals = () => {
  return i18n.t("dataGrid_terminals_helmet");
};

const nameAccess = () => {
  return i18n.t("menu_AccessRights");
};

const nameSettings = () => {
  return i18n.t("menu_Settings");
};

const nameSettingsCompany = () => {
  return i18n.t("menu_Company");
};

const nameSettingsDevices = () => {
  return i18n.t("menu_devices");
};

const nameSettingsLanguages = () => {
  return i18n.t("menu_Languages");
};

const nameUsers = () => {
  return i18n.t("menu_Users");
};

const nameShops = () => {
  return i18n.t("menu_shopUnit");
};

const nameReports = () => {
  return i18n.t("menu_Reports");
};

const nameReportsBiling = () => {
  return i18n.t("menu_Biling");
};

const nameReportsErrors = () => {
  return i18n.t("menu_Errors");
};

const nameReportsActivity = () => {
  return i18n.t("menu_Activity");
};

const nameReportsLogs = () => {
  return i18n.t("menu_FullLogs");
};

const dashboardsRoutes = {
  id: nameDashboard(),
  name: nameDashboard(),
  path: "/dashboard",
  icon: <Sliders />,
  containsHome: true,
  component: Default,
  children: null,
  guard: AuthGuard,
};

const accessRights = {
  id: nameAccess(),
  name: nameAccess(),
  icon: <List />,
  component: null,
  children: [
    {
      path: "/access/users",
      name: nameUsers(),
      guard: AuthGuardAdminMM,
      component: DataGridUsers,
    },
    {
      path: "/access/shopUnit",
      name: nameShops(),
      guard: AuthGuardAdminMM,
      component: DataGridShopUnit,
    },
  ],
};

const reports = {
  id: nameReports(),
  name: nameReports(),
  icon: <PieChart />,
  component: null,
  children: [
    {
      path: "/reports/biling",
      name: nameReportsBiling(),
      component: ShowBiling,
      guard: AuthGuardAdminMM,
    },
    {
      path: "/reports/errors",
      name: nameReportsErrors(),
      component: ShowErrors,
      guard: AuthGuardAdminMM,
    },
    {
      path: "/reports/activity",
      name: nameReportsActivity(),
      component: ShowActivity,
      guard: AuthGuardAdminMM,
    },
    {
      path: "/reports/full",
      name: nameReportsLogs(),
      component: ShowFullLog,
      guard: AuthGuardAdminMM,
    },
  ],
};

const settings = {
  id: nameSettings(),
  name: nameSettings(),
  icon: <Tool />,
  component: null,
  children: [
    {
      name: nameSettingsCompany(),
      path: "/settings/company",
      guard: AuthGuardAdmin,
      component: DataGridCompany,
    },
    {
      name: nameSettingsDevices(),
      path: "/settings/devices",
      guard: AuthGuardAdmin,
      component: DataGridDevices,
    },
    {
      name: nameSettingsLanguages(),
      path: "/settings/languages",
      guard: AuthGuardAdmin,
      component: DataGridLang,
    },
  ],
};

const management = {
  id: "Management",
  icon: <Settings />,
  component: null,
  children: [
    {
      name: "Backup",
      path: "/management/departments",
      guard: AuthGuardAdmin,
      component: Blank,
    },
    {
      name: "Status",
      path: "/management/devices",
      guard: AuthGuardAdmin,
      component: Blank,
    },
    {
      name: "Information",
      path: "/management/information",
      guard: AuthGuardAdmin,
      component: Blank,
    },
    {
      name: "Branding",
      path: "/management/branding",
      guard: AuthGuardAdmin,
      component: Blank,
    },
    {
      name: "Outgoing communication templates",
      path: "/management/outgoing",
      guard: AuthGuardAdmin,
      component: EmailTemplatesGrid,
    },
    {
      name: "Api settings",
      path: "/management/api",
      guard: AuthGuardAdmin,
      component: Blank,
    },
    {
      name: "Licenses",
      path: "/management/licenses",
      guard: AuthGuardAdmin,
      component: Blank,
    },
  ],
};

const editUser = {
  id: "editUser",
  path: "/access/users/edit/:id",
  guard: AuthGuardAdminMM,
  component: EditUser,
};

const editLang = {
  id: "editLang",
  path: "/settings/languages/edit/:id",
  guard: AuthGuardAdminMM,
  component: EditLang,
};

const profileRoutes = {
  id: "Profile",
  path: "/profile",
  component: Profile,
  children: null,
  guard: AuthGuard,
};

const deviceRouts = {
  id: "Device",
  path: "/settings/devices/:id",
  guard: AuthGuardAdmin,
  component: ShowDevice,
};

const companyRouts = {
  id: "company",
  path: "/settings/company/:id",
  guard: AuthGuardAdmin,
  component: ShowCompany,
};

const editShopUnit = {
  id: "editShopUnit",
  path: "/access/shopUnit/edit/:id",
  guard: AuthGuardAdminMM,
  component: EditShopUnit,
};

const showShopUnit = {
  id: "showShopUnit",
  path: "/access/shopUnit/show/:id",
  guard: AuthGuardAdminMM,
  component: ShowShopUnit,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  children: [
    {
      path: "/login-individual",
      name: "Sign In I",
      component: SignIn,
    },
    {
      path: "/login-business",
      name: "Sign In B",
      component: SignInB,
    },
    {
      path: "/reset-password-individual",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/change-password",
      name: "Change Password",
      component: ChangePassword,
    },
    {
      path: "/reset-password-business",
      name: "Reset Password",
      component: ResetPasswordBusiness,
    },
    {
      path: "/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/500",
      name: "500 Page",
      component: Page500,
    },
    {
      path: "/activate",
      name: "Activate",
      component: Activate,
    },
    {
      path: "/qrcode/:id",
      name: "QR Code",
      component: QrCode,
    },
  ],
  component: null,
};

const landingRoutes = {
  id: "Retailo",
  path: "/",
  component: Landing,
  children: null,
};

const terminalRoutes = {
  id: nameTerminals(),
  path: "/terminals",
  icon: <Terminal />,
  component: DataGridTerminals,
  children: null,
  guard: AuthGuard,
};

const terminalDeviceRoutes = {
  path: "/terminals/:id",
  component: ShowLockerTerminals,
  children: null,
  guard: AuthGuard,
};

const ordersRoutes = {
  id: nameOrders(),
  icon: <ShoppingCart />,
  path: "/orders",
  component: DataGridOrders,
  children: null,
  guard: AuthGuard,
};

const orderShowRoutes = {
  path: "/orders/:id",
  component: ShowOrder,
  children: null,
  guard: AuthGuard,
};

// This route is only visible while signed in
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  terminalRoutes,
  profileRoutes,
  deviceRouts,
  companyRouts,
  accessRights,
  reports,
  settings,
  management,
  editShopUnit,
  showShopUnit,
  editUser,
  editLang,
  terminalDeviceRoutes,
  ordersRoutes,
  orderShowRoutes,
];
// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes visible in the sidebar
export const sidebarRoutesAdmin = [
  dashboardsRoutes,
  ordersRoutes,
  orderShowRoutes,
  terminalRoutes,
  accessRights,
  settings,
  reports,
  management,
];
export const sidebarRoutesMerchant = [
  dashboardsRoutes,
  ordersRoutes,
  orderShowRoutes,
  terminalRoutes,
  accessRights,
  //reports,
];

export const sidebarRoutesManager = [
  dashboardsRoutes,
  ordersRoutes,
  orderShowRoutes,
  terminalRoutes,
  accessRights,
  //reports,
];

export const sidebarRoutesWorker = [
  dashboardsRoutes,
  ordersRoutes,
  orderShowRoutes,
  terminalRoutes,
];

export const sidebarRoutesGuest = [];
