export const URL_API = "https://sephora.retailo.com.tr:52713";

export const DEFAULT_LANG = "en";

export const PHONE_PREFIX = "+90";
export const ZIP_CODE = "XX-XXX";
export const ZIP_CODE_REGEX = /^[0-9]{2}-[0-9]{3}$/;

export const PHONE_PREFIXES_ARRAY = [
  { value: "+45", label: "+45" },
  { value: "+46", label: "+46" },
  { value: "+34", label: "+34" },
  { value: "+48", label: "+48" },
  { value: "+38", label: "+38" },
  { value: "+33", label: "+33" },
];
